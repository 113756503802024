<template>
	<div>
		<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
		<div v-if="!pageProcesses.isLoading">
			<div class="row" v-if="contact != null">
				<div class="col-12 col-md-6">
					<div class="card card-custom card-stretch gutter-b">
						<div class="card-header border-0 pt-5">
							<h3 class="card-title align-items-start flex-column">
								<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.CustomerCenter.RecommendationCenter.Contact.title') }}</span>
							</h3>
						</div>
						<div class="card-body pt-3">
							<div v-if="contact.subscriber != null && contact.subscriber.subscriber_contact != null && confirmed === ''">
								<div class="prinor-table">
									<b-table :data="contact.subscriber.subscriber_contact.input" :paginated="false" :show-header="false">
										<b-table-column field="name" label="" v-slot="props" :sortable="false" :searchable="false" cell-class="text-valign">
											<template>
												<span class="truncate">{{ props.row.name }}</span>
											</template>
										</b-table-column>
										<b-table-column field="value" label="" v-slot="props" :sortable="false" :searchable="false" cell-class="text-valign">
											<template>
												<span class="truncate">{{props.row.value }}</span>
											</template>
										</b-table-column>
									</b-table>
								</div>
								<div class="mt-5">
									<b-form-group label="Hat dieser Kunde den Termin wahrgenommen?" label-class="mb-3">
										<b-button variant="success" class="mr-3" v-on:click="confirmed = 'y'">Ja</b-button>
										<b-button variant="danger" v-on:click="confirmed = 'n'">Nein</b-button>
									</b-form-group>
								</div>
							</div>
							<div v-if="confirmed === 'y'">
								<h1>Der Kundenkontakt wurde hiermit bestätigt. Sie können die Seite nun schließen.</h1>
							</div>
							<div v-if="confirmed === 'n'">
								<h1>Der Kundenkontakt wurde hiermit nicht bestätigt. Sie können die Seite nun schließen.</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
// import ContentLoading from '@/view/component/misc/ContentLoading';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {mapGetters} from "vuex";
import Placeholder from "@/data/survey/Placeholder";
import ContentLoading from "@/view/component/misc/ContentLoading";
// import NoData from "@/view/component/misc/NoData";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
// import NoData from "@/view/component/misc/NoData";

export default {
	name: 'RecommendationCenter',
	components: {ContentLoading},
	// components: {NoData, ContentLoading },
	data() {
		return {
			contact: null,
			confirmed: '',
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.CustomerCenter.title'),
			},
			{ title: this.$t('VIEWS.CustomerCenter.RecommendationCenter.title') },
		]);
		this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		PRINOR_PROCESSES.isLoading();

		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),

		personalPlaceholder: function() {
			return new Placeholder(this);
		},
	},
	methods: {
		onGet: function() {
			this.isLoading = true;
			this.get().then(data => {
				this.contact = data.data;
			});
		},
		get: function() {
			let url = 'all';
			if ('ccd' in this.$route.query)
				url = this.$route.query.ccd;

			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('recommendation-center/' + url).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
